<template>
    <section>
        <TitleBar title="Renk Detayları" :modi="modi" />
        <b-tabs>
            <b-tab-item class="columns is-multiline" label="Genel">
                <b-field class="column is-6 box" label="Tanım" grouped group-multiline>
                    <b-field class="column is-12" label="ID">
                        <b-input v-model="color.id" disabled></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Renk Açıklaması">
                        <b-input v-model="color.name" :disabled="modi == 'show'"></b-input>
                    </b-field>
                </b-field>

                <b-field class="column is-6 box" label="CIE L*a*b*" grouped group-multiline>
                    <b-field class="column is-12" label="L*">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="00.000"
                            controls-position="compact"
                            controls-alignment="right"
                            v-model="color.ciel"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                    <b-field class="column is-12" label="a*">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="00.000"
                            controls-position="compact"
                            controls-alignment="right"
                            v-model="color.ciea"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                    <b-field class="column is-12" label="b*">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="00.000"
                            controls-position="compact"
                            controls-alignment="right"
                            v-model="color.cieb"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                </b-field>

                <b-field class="column is-12 box" label="Renk Reçetesi Tablosu" grouped group-multiline>
                    <b-field class="column is-12" position="is-right" grouped group-multiline>
                        <b-button class="column" type="is-success is-light" icon-left="plus" label="Ekle" @click="bom_add" :disabled="modi == 'show'" />
                        <b-button class="column ml-1" type="is-warning is-light" icon-left="import" label="Temizle" disabled />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Yazdır" />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" />
                    </b-field>

                    <b-table class="column is-12" :key="$store.state.ui.flush" :data="color.boms" :mobile-cards="false" striped narrowed>
                        <template slot="empty">
                            <div class="is-12 has-text-centered">
                                Renk reçetesi kaydı bulunamadı.
                            </div>
                        </template>

                        <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-5) }}</b-table-column>
                        <b-table-column field="item.code" label="Malzeme Kodu" v-slot="props" sortable>
                            <MaterialAutofill v-model="props.row.item" :disabled="!!props.row.id" />
                        </b-table-column>
                        <b-table-column field="item.name" label="Malzeme Açıklaması" v-slot="props" sortable>{{ props.row.item.name }}</b-table-column>
                        <b-table-column field="lotnum" label="Parti No" v-slot="props" sortable>
                            <LotAutofill v-model="props.row.lotnum" @value="(v) => (props.row.lotnum = v)" :id_material="props.row.item.id" :disabled="!!props.row.id" />
                        </b-table-column>
                        <b-table-column field="quantity" label="Miktar (KG)" v-slot="props" numeric sortable>
                            <b-numberinput
                                type="is-link is-light"
                                placeholder="0.00"
                                controls-alignment="right"
                                controls-position="compact"
                                v-model="props.row.quantity"
                                :disabled="modi == 'show'"
                                expanded
                            ></b-numberinput>
                        </b-table-column>
                        <b-table-column field="action" label="Aksiyon" v-slot="props">
                            <b-button type="is-danger is-light" icon-left="delete" @click="bom_delete(props.row.id, props.index)" :disabled="modi == 'show'" />
                        </b-table-column>
                    </b-table>
                </b-field>
            </b-tab-item>

            <b-tab-item label="Kayıt Geçmişi" :disabled="!color.id">
                <MetaDetails v-if="$route.query.id" />
            </b-tab-item>
        </b-tabs>

        <b-field position="is-right">
            <b-button class="ml-1" type="is-success is-light" icon-left="check" label="Kaydet" :disabled="modi == 'show'" @click="color_save" />
            <b-button class="ml-1" type="is-danger is-light" icon-left="close" label="Iptal" @click="color_cancel" />
        </b-field>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";
import MaterialAutofill from "@/components/MaterialAutofill.vue";
import LotAutofill from "@/components/LotAutofill.vue";
import MetaDetails from "@/components/MetaDetails.vue";

export default {
    name: "ColorDetails",
    components: {
        TitleBar,
        MaterialAutofill,
        LotAutofill,
        MetaDetails
    },
    data: () => ({
        modi: "",
        color: {
            id: "",
            boms: []
        }
    }),
    created() {
        this.color.id = this.$route.query.id || "";
        this.modi = this.$route.query.modi || "add";
        this.color_read();
    },
    methods: {
        color_read() {
            if (this.modi == "add") return;
            this.$apollo.queries.readColor.start();
        },
        color_save() {
            var err = "";
            var payload = this.color;
            delete payload.createdat;

            if (!payload.name) err = "Renk açıklaması boş olamaz.";
            if (!payload.ciel) err = "CIE L* boş olamaz.";
            if (!payload.ciea) err = "CIE a* boş olamaz.";
            if (!payload.cieb) err = "CIE b* boş olamaz.";

            if (err)
                return this.$buefy.snackbar.open({
                    type: "is-warning",
                    message: err,
                    position: "is-bottom-right"
                });

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($color: String) {
                            saveColor(color: $color)
                        }
                    `,
                    variables: {
                        color: JSON.stringify(payload)
                    }
                })
                .then(() => {
                    this.$buefy.snackbar.open({
                        type: "is-success",
                        message: "Başarılı",
                        position: "is-bottom-right"
                    });
                    return this.$router.push({ path: "/lab/renk/liste" });
                })
                .catch(() => {
                    return this.$buefy.snackbar.open({
                        type: "is-danger",
                        message: "Bir hata oluştu.",
                        position: "is-bottom-right"
                    });
                });
        },
        color_cancel() {
            //this.$router.go(-1);
            this.$router.push({ path: "/lab/renk/liste" });
        },
        bom_add() {
            this.color.boms.push({ id: "", item: {}, quantity: 0.0 });
        },
        bom_delete(id, index) {
            if (!id) this.color.boms.splice(index, 1);
        }
    },
    apollo: {
        readColor: {
            query: gql`
                query readColors($config: String) {
                    readColors(config: $config) {
                        id
                        name
                        ciel
                        ciea
                        cieb
                        boms {
                            id
                            item {
                                id
                                code
                                name
                            }
                            quantity
                            lotnum
                        }
                    }
                }
            `,
            variables() {
                return {
                    config: JSON.stringify({
                        skip: 0,
                        take: 1,
                        sorts: [],
                        filters: { id: this.color.id }
                    })
                };
            },
            update(data) {
                this.$apollo.queries.readColor.stop();
                this.color = data.readColors[0];
                this.color.createdat = new Date(this.color.createdat);

                if (this.modi == "ref") {
                    delete this.color.id;
                    for (var bom of this.color.boms) delete bom.id;
                }
            }
        }
    }
};
</script>
